import { Injectable } from '@angular/core';

import {
  Firestore,
  collectionData,
  collection,
  CollectionReference,
  doc,
  docData,
  setDoc,
  addDoc,
  where,
  getDocs,
  query,
  Query
} from "@angular/fire/firestore";
// import { AngularFirestore, CollectionReference, Query, collection, where, query, collectionData } from '@angular/fire/firestore';

// import { orderBy, query } from "@firebase/firestore";
import { Observable } from 'rxjs';

import { Agency } from "../../models/agencies/agencies.interface";
import { getFunctions, httpsCallable } from '@angular/fire/functions';


@Injectable({
  providedIn: 'root'
})
export class AgenciesService {
  agencies$: Observable<Agency[]>;
  copyOfAgency: Agency;

  constructor(private firestore: Firestore) { };

  public getAgencies(): Observable<Agency[]> {
    const agencyRef = collection(this.firestore, "agencies");
    return collectionData(agencyRef, { idField: "id" }) as Observable<Agency[]>;
  }

  public getAgencyById(id: string): Observable<Agency> {
    const bookRef = doc(this.firestore, `agencies/${id}`);
    return docData(bookRef, { idField: 'id' }) as Observable<Agency>;
  } 

  public async saveAgency(agency: Agency) {
    const colRef = collection(this.firestore, "agencies");
    const newDoc = await addDoc(colRef, agency)
    return newDoc.id;
  } 

  public updateAgencyById(agency: Agency) {
    const docRef = doc(this.firestore, `agencies/${agency.id}`);
    setDoc(docRef, agency);
  } 

 /* public async getUsersByAgency(agencyName: string){
    const agencyRef = collection(this.firestore, "userProfiles");
    const q =  query(agencyRef, where("agencyName", "==", agencyName))
    const querySnapshot = await getDocs(q);
    var emptyUserArr = [];
    querySnapshot.forEach((doc) => {
      console.log(doc.data())
      if(doc.data()) {
        emptyUserArr.push(doc.data());
      } 
    });
    console.log(emptyUserArr);
    return emptyUserArr;

console.log(agencyName)
return collectionData<Agency>(
  query<Agency>(
    collection(this.firestore, "userProfiles") as CollectionReference<Agency>,
    where('size', '==', 'large')
  ),
  { idField: "id" }
);
  } */

 /* public getRecordings2(): Observable<Recording[]> {
    return collectionData<Recording>(
      query<Recording>(
        collection(this.firestore, "recordings") as CollectionReference<Recording>,
        orderBy("id")
      ),
      { idField: "id" }
    );
  } */

  public getUsersByAgency(agencyId: string): Observable<Agency[]>{
    return collectionData<Agency>(
      query<Agency>(
        collection(this.firestore, "userProfiles") as CollectionReference<Agency>,
        where('agencyId', '==', agencyId),
      ),
      { idField: "id" }
    );
    }
  public getSkillsByAgencyId(agencyId: string): Observable<Agency[]>{
      return collectionData<Agency>(
        query<Agency>(
          collection(this.firestore, "skillTemplates") as CollectionReference<Agency>,
          where('agencyId', '==', agencyId),
        ),
        { idField: "id" }
      );
    }

  public getCoachesandSupervisorsByAgency(agencyId: string): Observable<any[]>{
    let query1: Query = query(
      collection(this.firestore, "userProfiles") as CollectionReference<any>,
      where('agencyId', '==', agencyId),
      where('role', 'array-contains-any', ["Coach", "Lead Peer Coach", "Supervisor"])
    );

    return collectionData(query1, { idField: "id" });
  }

  public getLearnersByAgency(agencyId: string): Observable<any[]>{
    let query1: Query = query(
      collection(this.firestore, "userProfiles") as CollectionReference<any>,
      where('agencyId', '==', agencyId),
      where('status', '==', 'active'),
      where('role', 'array-contains-any', ["Learner"])
    );

    return collectionData(query1, { idField: "id" });
  }

  public getCoachesByAgency(agencyId: string): Observable<any[]>{
    let query1: Query = query(
      collection(this.firestore, "userProfiles") as CollectionReference<any>,
      where('agencyId', '==', agencyId),
      where('status', '==', 'active'),
      where('role', 'array-contains-any', ["Coach", "Lead Peer Coach"])
    );

    return collectionData(query1, { idField: "id" });
  }

  public getPraxusAdmins(): Observable<any[]>{
    let query1: Query = query(
      collection(this.firestore, "userProfiles") as CollectionReference<any>,
      where('status', '==', 'active'),
      where('role', 'array-contains-any', ["Praxus Admin"])
    );

    return collectionData(query1, { idField: "id" });
  }

  public getLearnersByCoachId(userId:string, agencyId?: string): Observable<any[]>{
    let query1: Query = query(
      collection(this.firestore, "userProfiles") as CollectionReference<any>,
    //  where('agencyId', '==', agencyId),
      where('status', '==', 'active'),
      where('coach.id', '==', userId),

   //   where('role', 'array-contains-any', ["Coach", "Lead Peer Coach"])
    );

    return collectionData(query1, { idField: "id" });
  }

  public async getSkillLevels(userId?: string, agencyId?: string, skillId?: string, coachId?: string) {
    const functions = getFunctions();

    const getSkillLevels = httpsCallable(functions, 'getSkillLevels');
  
    return getSkillLevels({userId, agencyId, skillId, coachId}); 
  }
  
  public async getFilteredUsers(agencyQuery: any) {
    const functions = getFunctions();

    const groupQueries = httpsCallable(functions, 'groupQueries');
  
    return groupQueries({agencyQuery}); 
  }

  public async getActivityFilter(activityQuery: any) {
    const functions = getFunctions();

    const getActivityQueries = httpsCallable(functions, 'getActivityQueries');
  
    return getActivityQueries({activityQuery}); 
  }

  public async getRecordingsReport(recordingsQuery:any) {
    const functions = getFunctions();

    const getRecordingsReport = httpsCallable(functions, 'getRecordingsReport');
  
    return getRecordingsReport({recordingsQuery}); 
  }

  public async addAgencySkillsAndActivities(agencyId: string, skillsActivitiesObj: any) {
    const functions = getFunctions();

    const addAgencySkillsAndActivities = httpsCallable(functions, 'addAgencySkillsAndActivities');
  
    return addAgencySkillsAndActivities({agencyId, skillsActivitiesObj}); 
  }

  public async syncSkillTemplatesForUsers(skillIds: any, agencyId: string) {
    const functions = getFunctions();

    const syncSkillTemplatesForUsers = httpsCallable(functions, 'syncSkillTemplatesForUsers');
  
    return syncSkillTemplatesForUsers({skillIds, agencyId}); 
  }

  async sendWelcomeEmail(userId: string) {
    const functions = getFunctions();
  
    const sendWelcomeEmail = httpsCallable(functions, 'sendWelcomeEmail');
  
    return sendWelcomeEmail({userId}); 
  }
  
  async sendResetPassword(userId: string) {
    const functions = getFunctions();
  
    const sendResetPassword = httpsCallable(functions, 'sendResetPassword');
  
    return sendResetPassword({userId}); 
  }


}
