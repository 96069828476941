import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import {
  Firestore,
  collectionData,
  collection,
  CollectionReference,
  doc,
  docData,
  setDoc,
  addDoc
} from "@angular/fire/firestore";
import { orderBy, query } from "@firebase/firestore";

import { Recording } from "../../models/recordings/recordings.interface";
import { Transcript, TranscriptNote } from "../../models/transcripts/transcripts.interface";
import { getFunctions, httpsCallable } from "firebase/functions";

@Injectable({
  providedIn: "root",
})
export class RecordingService {
  recording$: Observable<Recording[]>;

  constructor(private firestore: Firestore) {}

  public getRecordings(): Observable<Recording[]> {
    const notesRef = collection(this.firestore, "recordings");
    return collectionData(notesRef, { idField: "id" }) as Observable<Recording[]>;
  }

  public getRecordings2(): Observable<Recording[]> {
    return collectionData<Recording>(
      query<Recording>(
        collection(this.firestore, "recordings") as CollectionReference<Recording>,
        orderBy("id")
      ),
      { idField: "id" }
    );
  }



  public getRecordingById(id: string): Observable<Recording> {
    const bookRef = doc(this.firestore, `recordings/${id}`);
    return docData(bookRef, { idField: 'id' }) as Observable<Recording>;
  }


  public getTranscriptById(id: string): Observable<Transcript> {
    const bookRef = doc(this.firestore, `transcripts/${id}`);
    return docData(bookRef, { idField: 'id' }) as Observable<Transcript>;
  }

public addTranscriptNote(id: string, note: TranscriptNote) {
  console.log('addTranscriptNote', id, note)
  const functions = getFunctions();

  const addTranscriptNote = httpsCallable(functions, 'addTranscriptNote');

  const params = {
    transcriptId: id,
    transcriptNote: note
  }

  return addTranscriptNote(params)

}

public getTranscriptNotes(recordingId : string): Observable<TranscriptNote[]> {
  console.log('getTranscriptNotes', recordingId)
  return collectionData<TranscriptNote>(
    query<TranscriptNote>(
      collection(this.firestore, "transcripts", recordingId, "notes") as CollectionReference<TranscriptNote>,
      orderBy("creationTime")
    ),
    { idField: "id" }
  );
}


  public saveRecording(recording: Recording): string {

    var id = this.firestoreAutoId();
    const bookRef = doc(this.firestore, `recordings/${id}`);
    setDoc(bookRef, recording);

    return id
  }


  firestoreAutoId = (): string => {
    const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  
    let autoId = ''
  
    for (let i = 0; i < 20; i++) {
      autoId += CHARS.charAt(
        Math.floor(Math.random() * CHARS.length)
      )
    }
    return autoId
  }

}
