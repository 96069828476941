import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, take, switchMap } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import { query, where } from "firebase/firestore";

// import { AngularFireAuth } from @angular/fire/auth  didn't work
 // import { AngularFireAuth } from '@angular/fire/compat/auth';
 import { Auth, signInWithEmailAndPassword, getAuth, authState, sendPasswordResetEmail } from '@angular/fire/auth';
 import { from } from 'rxjs';
 import { getFunctions, httpsCallable } from '@angular/fire/functions';

 import {
  Firestore,
  collectionData,
  collection,
  CollectionReference,
  doc,
  docData,
  setDoc,
  addDoc,
  getDocs,
  getDoc,
  collectionGroup
} from "@angular/fire/firestore";
import { resourceUsage } from 'process';
import { Role } from '../models/role';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  user?: any;
  Role: Role
  constructor(private http: HttpClient, private auth: Auth, private firestore: Firestore, private router: Router) {
    
    this.currentUserSubject = new BehaviorSubject<any>(
      null
    );
    this.currentUser = this.currentUserSubject.asObservable();
    

  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

 async isAuthenticated() {
  return new Promise ((resolve, reject) => {
     this.auth.onAuthStateChanged(firebaseUser => {
      if (firebaseUser) {
         this.getUserById(firebaseUser.uid).subscribe((userProfile)=>{

            if(userProfile) {
             this.currentUserSubject.next(userProfile);
             if(userProfile.firstLogin == true) {
              this.router.navigate(['/authentication/first-sign-in'], {queryParams: {userId: userProfile.id}});

             }
                resolve(userProfile);
              } else {
                resolve(false);
                this.router.navigate(['/authentication/signin']);

              }
           })
      } else {
        resolve(false);
        this.router.navigate(['/authentication/signin']);

      }
    })
  }) 
}

 checkUserRoles(rolesToCheck: Role[]): boolean {
  return rolesToCheck.some((roleToCheck) =>
    this.currentUserValue.role.includes(roleToCheck)
  );
}


async login(username: string, password: string) {

  return signInWithEmailAndPassword(this.auth, username, password).then(async res => {
    // update the user's last logged in on time
     this.updateLastLoggedInOn();

     return res.user.uid;
     })
     .catch(err => {
    throw err.message;
  }); 
}

async updateLastLoggedInOn() {
  const functions = getFunctions();

  const updateLastLoggedInOn = httpsCallable(functions, 'updateLastLoggedInOn');

  return updateLastLoggedInOn(); 
}

async updateUserPassword(email: string, newPassword: string) {
  const functions = getFunctions();

  const updateGoal = httpsCallable(functions, 'updateUserPassword');

  return updateGoal({email, newPassword}); 
}

async updateFirstLoginStatus(userId: string) {
  const functions = getFunctions();

  const updateFirstLogin = httpsCallable(functions, 'updateFirstLogin');

  return updateFirstLogin({userId}); 
}

async getActivitiesToReview(userId: string, agencyId: any) {
  const functions = getFunctions();

  const getActivitiesToReview = httpsCallable(functions, 'getActivitiesToReview');

  return getActivitiesToReview({userId, agencyId}); 
}

async getLateActivities(userId: string, agencyId: any) {
  const functions = getFunctions();

  const getLateActivities = httpsCallable(functions, 'getLateActivities');

  return getLateActivities({userId, agencyId}); 
}

async getRecordingsPending(userId: string, agencyId: any) {
  const functions = getFunctions();

  const getRecordingsPending = httpsCallable(functions, 'getRecordingsPending');

  return getRecordingsPending({userId, agencyId}); 
}

async getRecordingsUploaded(userId: string, agencyId: any) {
  const functions = getFunctions();

  const getRecordingsUploaded = httpsCallable(functions, 'getRecordingsUploaded');

  return getRecordingsUploaded({userId, agencyId}); 
}

updateGuidedTours(userId: string, seenGuidedTour: string) {
  const functions = getFunctions();

  const updateGuidedTours = httpsCallable(functions, 'updateGuidedTours');

  return updateGuidedTours({userId, seenGuidedTour}); 
}

async resetDemoData(userId: string) {
  const functions = getFunctions();

  const resetDemoData = httpsCallable(functions, 'resetDemoData');

  return resetDemoData({userId}); 
}



  // Replace "any" type with User type.

   getUserById(id: string) {
    const bookRef = doc(this.firestore, `userProfiles/${id}`);    
    return docData(bookRef, { idField: 'id' }) as Observable<any>;
  } 
  userDoc:any
  skills:any;

  public getSkillsByUser(userId: string): Observable<any[]> {
    const skillsRef = collection(this.firestore, `userProfiles/${userId}/skills`);
    return collectionData(skillsRef, { idField: "id" }) as Observable<any[]>;
  }

  public getSkillById(userId: string, skillId: string): Observable<any> {
    const skillsRef = doc(this.firestore, `userProfiles/${userId}/skills/${skillId}`);
    return docData(skillsRef, { idField: "id" }) as Observable<any>;
  }

  public getActivityById(userId: string, skillId: string, activityId: string): Observable<any> {
    const skillsRef = doc(this.firestore, `userProfiles/${userId}/skills/${skillId}/activities/${activityId}`);
    return docData(skillsRef, { idField: "id" }) as Observable<any>;
  }
  
  public getActivitiesByUserAndSkill(userId: string, skillId: string): Observable<any[]> {
    const activitiesRef = collection(this.firestore, `userProfiles/${userId}/skills/${skillId}/activities`);
    return collectionData(activitiesRef, { idField: "id" }) as Observable<any[]>;
   /* return collectionData<any>(
      query<any>(
        collection(this.firestore, "userProfiles/${userId}/skills/${skillId}/activities") as CollectionReference<any>,
        where('agencyId', '==', agencyId),
      ),
      { idField: "id" }
    ); */
  }

  public getGoalsByUser(userId: string): Observable<any[]> {
    const goalsRef = collection(this.firestore, `userProfiles/${userId}/goals`);
    return collectionData(goalsRef, { idField: "id" }) as Observable<any[]>;
  }
  
  public getTasksByUserAndGoal(userId: string, taskId: string): Observable<any[]> {
    const tasksRef = collection(this.firestore, `userProfiles/${userId}/goals/${taskId}/tasks`);
    return collectionData(tasksRef, { idField: "id" }) as Observable<any[]>;
  }

  public getSkillLevelByUserId(userId: string): Observable<any> {
    const skillsRef = doc(this.firestore, `userProfiles/${userId}/skills/levels`);
    return docData(skillsRef, { idField: "id" }) as Observable<any>;
  }

  public updateUserPhone(userId: string, phone: string) {
    const functions = getFunctions();

    const updateUserPhone = httpsCallable(functions, 'updateUserPhone');

    return updateUserPhone({userId, phone}); 
  }

 /* public getAllSkillsAndActivitiesByUser(userId:string):Observable<any>{
    var skills = []
    //this.getSkillsByUser(userId).pipe(take(1)).switchMap((data)=> return this.getActivitiesByUserAndSkill(userId, data.skill))

    return this.getSkillsByUser(userId).subscribe((res)=>{
      res.forEach((eachSkill)=>{
        this.getActivitiesByUserAndSkill(userId, eachSkill.id).subscribe((activities)=>{
          eachSkill.activities = activities;
        })
      });
      return res;
    });
  } */

 // getSkillsAndActivitiesByIds(userId: string, )
  

  /* async getUserById2(id: string) {
    
    const userRef = collection(this.firestore, "userProfiles");
    const q =  query(userRef, where("uid", "==", id))
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      if(doc.data()) {
        this.user = doc.data();
      } else {
        this.user = undefined;
      }
    });
    return this.user; 
  } */

  
  resetPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, email, null);
  }

  logout() {
    // remove user from local storage to log user out
   // localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.auth.signOut().then((res)=>{
      this.router.navigate(['/authentication/signin']);

    }) 
    return of({ success: false });
  }

}
